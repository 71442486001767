import type { NextPage } from 'next';
import { getElternProps } from '../lib/api/eltern';
import { BannerAd } from '../lib/api/types/banner-ad';
import { CalendarEvent } from '../lib/api/types/calendar-event';
import EventMainSection from '../components/EventMainSection';
import { eventExpired } from '../lib/utility/date-helper';

interface Props {
  data: {
    calendarEvents: CalendarEvent[];
    bannerAds: BannerAd[];
  };
}

const Home: NextPage<Props> = ({ data }) => {
  const filteredExpiredEvents = data.calendarEvents.filter(
    (cE) => !eventExpired(cE.endDate)
  );

  return (
    <EventMainSection
      bannerAds={data.bannerAds}
      calendarEvents={filteredExpiredEvents}
      title="Kurse und Einzelveranstaltungen"
    />
  );
};

export const getStaticProps = getElternProps;

export default Home;
